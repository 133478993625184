























































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { ACT_LOGIN_USER } from "@/store/actions/types";
import {
  notificationNetworkError,
  notifyError,
} from "@/components/Notification";
import rules from "@/components/account/rules";
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";

export default Vue.extend({
  name: "Login",
  inject: ["callCenterProvider"],
  data() {
    return {
      loging: false,
      validForm: false,
      sms: null,
      rules: {
        email: rules.email,
        required: rules.required,
      },
      email: "",
      password: "",
      wrongCred: false, // activates appropriate message if set to true
      loading: false, // property for button loading button state
    };
  },
  computed: {
    ...mapState(["profile", "dataCRM"]),
    ...mapGetters([
      "loggedIn",
      "isSuper",
      "isAdmin",
      "isWorker",
      "isDoctor",
      "isSupervisor",
    ]),
  },
  mounted() {
    (this as any).destroyToken();
    (this as any).$parent.$parent.$parent.drawer = false;
    (this as any).$parent.$parent.$parent.topBar = false;
    if (!this.loggedIn) {
      clearInterval((this as any).sms);
    }
  },

  methods: {
    ...mapActions(["actGeneralConfig", "actGetContacts"]),
    ...mapMutations(["updateStatus", "updateactivesms", "destroyToken"]),

    validforms() {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (
        (this as any).email != "" &&
        pattern.test((this as any).email) &&
        (this as any).password != ""
      ) {
        (this as any).validForm = true;
      } else {
        (this as any).validForm = false;
      }
    },

    getContacts() {
      (this as any).actGetContacts();
    },

    async loginUser() {
      // call loginUSer action
      if ((this as any).validForm == false) return;
      (this as any).loging = true;

      try {
        await this.$store.dispatch(ACT_LOGIN_USER, {
          email: (this as any).email,
          password: (this as any).password,
        });
        await (this as any).actGeneralConfig();
        this.$vuetify.theme.themes.light = this.dataCRM.themes.light;
        (this as any).updateStatus("Idle");
        (this as any).wrongCred = false;
        (this as any).loging = false;
        if (
          this.$store.getters.loggedIn &&
          (this.$store.getters.isWorker ||
            this.$store.getters.isAdmin ||
            this.$store.getters.isSupervisor ||
            this.$store.getters.isSuper)
        ) {
          this.updateactivesms(true);
          setTimeout(() => {
            (this as any).$root.initDevice();
            (this as any).getContacts();
          }, 1000);
        }
        switch (this.profile.rol) {
          case "WORKER":
          case "SUPERVISOR":
            this.$router.push("/appointments");
            break;
          case "SUPER":
          case "ADMIN":
            this.$router.push("/appointments");
            break;
          case "DOCTOR":
            this.$router.push("/patients");
            break;
        }
      } catch (error) {
        if ((error as any).message == "Network Error") {
          notificationNetworkError();
          return;
        }

        notifyError(this.$t("errorMsg") as string);
        (this as any).wrongCred = true; // if the credentials were wrong set wrongCred to true
        // throw error;
      } finally {
        (this as any).loging = false;
      }
    },
    focusPassword() {
      (this as any).$refs.password.focus();
    },
  },
  updated() {
    this.validforms();
  },
});
